
import { computed, defineComponent, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

import {
  LMap,
  LIcon,
  LTileLayer,
  LMarker,
  LControlLayers,
  LControlZoom,
  // LTooltip,
  LPopup,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";
import CryptoJS from "crypto-js";

export default defineComponent({
  name: "Heatmaps",
  components: {
    LMap,
    LIcon,
    LTileLayer,
    LMarker,
    LControlLayers,
    LControlZoom,
    // LTooltip,
    LPopup,
  },
  setup() {
    setCurrentPageTitle("Komparasi Peta Suara");

    const store = useStore();

    store.dispatch("AllTerritory", { type: 4 });
    store.dispatch("AllComparison");

    store.commit("SET_ACTIVE_MENU", "map-comparison");

    const router = useRouter();

    const center = ref([-6.304159, 106.724997]);

    const zoom = ref(12);

    const year = 2019;

    const iconSize = ref([50, 50]);

    const path = window.location.origin + "/";
    const icon = path + "other/png/Group 212.png";

    const markers = computed(() => store.state.ComparisonModule.all);
    const kecamatan = computed(() => store.state.TerritoryModule.kecamatan);
    const kelurahan = computed(() => store.state.TerritoryModule.kelurahan);

    watch(markers, () => {
      // console.log(markers.value);
    });

    const form = ref({
      kecamatan: "",
      kelurahan: "",
    });

    const getDetail = (id, to) => {
      const dataId = CryptoJS.AES.encrypt(id.toString(), "PKS.id").toString();
      const dataYear = CryptoJS.AES.encrypt(
        year.toString(),
        "PKS.id"
      ).toString();
      router.push({
        name: "comparison.map.detail." + to,
        params: { id: dataId, year: dataYear },
      });
    };

    const getKecamatan = (el) => {
      form.value.kelurahan = "";
      if (el.target.value) {
        store.dispatch("AllTerritory", { type: 5, parent_id: el.target.value });
        updateDataWilayah(el.target.value);
      } else {
        updateTanpaWilayah();
        store.commit("SET_ALL_TERRITORY", { type: 5, territories: [] });
      }
    };

    const getKelurahan = (el) => {
      if (el.target.value) {
        updateDataWilayah(el.target.value);
      } else {
        updateDataWilayah(form.value.kecamatan);
      }
    };

    const updateDataWilayah = (wilayah) => {
      store.dispatch("AllComparison", {
        territory: wilayah,
      });
    };
    const updateTanpaWilayah = () => {
      store.dispatch("AllComparison");
    };

    return {
      center,
      markers,
      kecamatan,
      kelurahan,
      zoom,
      icon,
      year,
      iconSize,
      form,
      getKecamatan,
      getKelurahan,
      getDetail,
    };
  },
});
